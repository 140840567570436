import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Head from '../components/head';

import styles from './listpage.module.scss';

const TypePage = ({
  data: {
    allContentfulVehicle: { nodes: allVehicles },
  },
  pageContext: { ordering, type },
}) => {
  const [orderedVehicles, setOrderedVehicles] = useState([]);
  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => {
    let inOrder = [];

    ordering.forEach((thisPlate, i) => {
      const match = allVehicles.filter(
        ({ numberPlate }) => thisPlate === numberPlate
      );

      if (match) {
        inOrder.push(...match);
      }

      if (i + 1 >= ordering.length) {
        if (type === 'car') {
          setOrderedVehicles(
            inOrder.filter(({ vehicleType }) => vehicleType === 'Car')
          );
          setPageTitle('Cars');
        }

        if (type === 'camper') {
          setOrderedVehicles(
            inOrder.filter(({ vehicleType }) => vehicleType === 'Camper')
          );
          setPageTitle('Camper Vans');
        }

        if (type === 'van') {
          setOrderedVehicles(
            inOrder.filter(({ vehicleType }) => vehicleType === 'Van')
          );
          setPageTitle('Vans');
        }

        if (type === 'taxi') {
          setOrderedVehicles(
            inOrder.filter(({ vehicleType }) => vehicleType === 'Taxi')
          );
          setPageTitle('Taxis');
        }
      }
    });
  }, [allVehicles, ordering, type]);

  return (
    <Layout>
      {/* TODO: DESCRIPTION */}
      <Head title={`${pageTitle}`} noIndex={true} />

      <main className={styles.home}>
        <ul className={styles.grid}>
          {orderedVehicles.map(
            (
              {
                make,
                model,
                colour,
                numberPlate,
                gridImage: {
                  localFile: {
                    childImageSharp: { gatsbyImageData },
                  },
                },
              },
              i
            ) => (
              <li key={`allVehicles-${i}`} className={styles.item}>
                <Link
                  to={`/gallery/${numberPlate
                    .replace(' ', '-')
                    .toLowerCase()}/`}
                  className={styles.link}
                  aria-label={`View ${numberPlate}, a ${colour} ${make} ${model} parked in Hollingdean`}
                >
                  <GatsbyImage
                    image={gatsbyImageData}
                    alt={`${numberPlate}, a ${colour} ${make} ${model} parked in Hollingdean`}
                    placholder="dominantColor"
                    loading="lazy"
                  />
                  <h2 className={styles.numberplate}>{numberPlate}</h2>
                </Link>
              </li>
            )
          )}
        </ul>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query TypePage {
    allContentfulVehicle {
      nodes {
        id
        numberPlate
        colour
        make
        model
        vehicleType
        gridImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 400
                quality: 40
                placeholder: DOMINANT_COLOR
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  }
`;

export default TypePage;
